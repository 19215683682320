import React from 'react';

import {ResourceTopic, ResourceType, ResourceIndustry} from "../../utils/queries/resources";
import Select from 'react-select';

const ResourcePageSingleFilter: React.FC<{
    list: Array<ResourceTopic | ResourceType | ResourceIndustry>;
    itemKey: 'industry' | 'type' | 'topic';
    selected: ResourceTopic | ResourceType | ResourceIndustry;
    setSelected: (any) => void;
}> = ({setSelected, list, itemKey, selected}) => {
    const options = list.map((item) => ({
        label: item[itemKey],
        value: item.id,
        original: item,
    }));

    const onSelect = (option, action) => {
        const foundOption = option && option.value ? options.filter((opt) => opt.value === option.value)[0].original : null;
        setSelected(foundOption);
    }

    const customStyles = {
        control: (base) => ({
            ...base,
            width: 250,
            border: "1px solid #CCC",
            marginRight: 16,
            color: '#2ec0e7',
            cursor: "pointer",
            borderRadius: 0,
        }),
        option: (base, state) => ({
            ...base,
            color: '#2ec0e7',
            background: "white",

            "&:hover": {
                textDecoration: "underline",
                cursor: "pointer",
            },

            "&:focus": {
                textDecoration: "underline",
                cursor: "pointer",
            },
        }),
        singleValue: (base) => ({
            ...base,
            backgroundColor: '#2ec0e7',
            color: "white",
            padding: "3px 4px 1px",
            justifyContent: "center",
            display: "flex",
            borderRadius: 4,
        }),
        placeholder: (base) => ({
            ...base,
            color: '#2ec0e7',
            opacity: ".7"
        }),
        dropdownIndicator: (base) => ({
            ...base,
            color: '#2ec0e7',
        }),
        indicatorSeparator: (base) => ({
            ...base,
            display: 'none',
        })
    }

    return (
        <Select
            className="basic-single mb-2 lg:mb-0"
            classNamePrefix="select"
            defaultValue={null}
            isClearable={true}
            isSearchable={false}
            name={itemKey}
            options={options}
            styles={customStyles}
            placeholder={itemKey.charAt(0).toUpperCase() + itemKey.slice(1)}
            onChange={onSelect}
        />
    );
};

const ResourcePageFilter: React.FC<{
    types: ResourceType[];
    topics: ResourceTopic[];
    industries: ResourceIndustry[];

    selectedType: ResourceType | null;
    selectedTopic: ResourceTopic | null;
    selectedIndustry: ResourceIndustry | null;

    setType: (r: ResourceType) => void;
    setTopic: (r: ResourceTopic) => void;
    setIndustry: (r: ResourceIndustry) => void;
}> = ({types, topics, industries, selectedIndustry, selectedTopic, selectedType, setType, setIndustry, setTopic}) => {
    const [internalSelectedType, setInternalSelectedType] = React.useState<ResourceType>(selectedType);
    const [internalSelectedTopic, setInternalSelectedTopic] = React.useState<ResourceTopic>(selectedTopic);
    const [internalSelectedIndustry, setInternalSelectedIndustry] = React.useState<ResourceIndustry>(selectedIndustry);


    const onClick = () => {
        setType(internalSelectedType);
        setTopic(internalSelectedTopic);
        setIndustry(internalSelectedIndustry);
    };

    return (
        <div className="col-12">
            <div className="filter-wrapper flex flex-col lg:flex-row justify-start w-full items-center">
                <div className="font-bold mr-2">Filter content by:</div>
                <ResourcePageSingleFilter list={types} itemKey={'type'} selected={internalSelectedType}
                                          setSelected={setInternalSelectedType}/>
                <ResourcePageSingleFilter list={topics} itemKey={'topic'} selected={internalSelectedTopic}
                                          setSelected={setInternalSelectedTopic}/>
                <ResourcePageSingleFilter list={industries} itemKey={'industry'} selected={internalSelectedIndustry}
                                          setSelected={setInternalSelectedIndustry}/>
                <div className="cursor-pointer text-white rounded-full py-2 px-6 finalize-filter"
                     onClick={onClick}>Filter
                </div>
            </div>
        </div>
    );
}

export {ResourcePageFilter}