import * as React from "react";
import _ from "lodash";
import "./resources.scss";
import {
  PageHeaderCustomV2,
  PageHeaderStandard,
} from "../../components/page-header/page-header";
import { PageTitle } from "../../components/title/title";
import AmazeeIoMetadata from "../../components/metadata";
import AmazeePage from "../../components/layout";
import usePreviewData from "../../utils/usePreviewData";
import {
  PrismicRawResourceEdge,
  PrismicRawResourcePage,
  Resource,
  ResourceIndustry,
  ResourceTag,
  ResourceTopic,
  ResourceType,
} from "../../utils/queries/resources";
import { FeatureCard, StandardCard } from "./cards";
import { ResourcePageFilter } from "./filter";
import { PrismicPageDefinition } from "../../utils/queries/types";
import Linked from "../../utils/linkedItem";
import { AmazeeIOButton } from "../../components/button/button";

const ResourceInteruption: React.FC<{
  cta_text: string;
  cta_button_text: string;
  second_cta_button_text: string;
  cta_link: PrismicPageDefinition;
  second_cta_button_link: PrismicPageDefinition;
}> = ({
  cta_text,
  cta_button_text,
  cta_link,
  second_cta_button_text,
  second_cta_button_link,
}) => {
    return (
      <div className="col-12 resource-cta-wrapper">
        <div className="resource-cta">
          <div className="row flex justify-center">
            <div className="col-12 col-md-8 flex justify-center flex-col items-center">
              <div
                className="text-white text-center mb-4"
                style={{ fontSize: 30 }}
              >
                {cta_text}
              </div>
              <div className="flex buttons">
                <Linked link_to={cta_link}>
                  <AmazeeIOButton classes="inverted inline-block">
                    {cta_button_text}
                  </AmazeeIOButton>
                </Linked>
                <Linked link_to={second_cta_button_link}>
                  <AmazeeIOButton classes="inline-block transparent">
                    {second_cta_button_text}
                  </AmazeeIOButton>
                </Linked>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

const ResourcePage = ({
  pageContext,
  location,
}: {
  location: Location;
  pageContext: {
    rawResources: PrismicRawResourceEdge[];
    rawPageData: PrismicRawResourcePage;
  };
}) => {
  const { rawResources, rawPageData } = pageContext;
  const resources = rawResources.map(
    (rawResource) => new Resource(rawResource.node.data)
  );
  const [maxResources, setMaxResources] = React.useState(9);

  const [renderableResources, setRenderableResources] = React.useState<
    Resource[]
  >(resources);

  if (!rawPageData) return null;
  const result = usePreviewData(
    rawPageData.data,
    "allPrismicResourcePage",
    true
  ) as PrismicRawResourcePage["data"];

  const title = result.meta_title ? result.meta_title : result.title;

  const availableResourceTypes: ResourceType[] = _.uniqBy(
    resources.map((resource) => resource.resourceType),
    (tag: ResourceType) => {
      return tag.id;
    }
  );
  const availableResourceTags: ResourceTag[] = _.uniqBy(
    resources.map((resource) => resource.tags).flat(),
    (tag: ResourceTag) => {
      return tag.id;
    }
  );

  const availableResourceTopics: ResourceTopic[] = availableResourceTags.filter(
    (tag) => tag.doc_type === "resource_topic"
  ) as ResourceTopic[];
  const availableResourceIndustries: ResourceIndustry[] = availableResourceTags.filter(
    (tag) => tag.doc_type === "resource_industry"
  ) as ResourceIndustry[];

  const [selectedType, setSelectedType] = React.useState<ResourceType>(null);
  const [selectedTopic, setSelectedTopic] = React.useState<ResourceTopic>(null);
  const [selectedIndustry, setSelectedIndustry] = React.useState<
    ResourceIndustry
  >(null);

  const featured = resources.filter((r) => r.featured);
  const firstFeatured = featured.length > 0 ? featured[0] : null;

  React.useEffect(() => {
    const filterApplied = !!(selectedType || selectedTopic || selectedIndustry);
    const filteredResources = resources
      .filter((r) => {
        // If there's no filters, remove featured articles
        if (!filterApplied) {
          return !r.featured;
        }
        // If there's a filter applied, include articles. Ask Jason about this logic.
        return true;

        // This is the same logic, it's just a little tougher to read.
        // return filterApplied || !r.featured;
      })
      .filter((r) =>
        r.matchesFilters({
          type: selectedType,
          topic: selectedTopic,
          industry: selectedIndustry,
        })
      );
    setRenderableResources(filteredResources);
  }, [selectedType, selectedTopic, selectedIndustry]);

  React.useEffect(() => {
    // When the filters change, change the max resources
    setMaxResources(9);
  }, [selectedType, selectedTopic, selectedIndustry]);

  const incrementMaxResults = React.useCallback(() => {
    setMaxResources(maxResources + 3);
  }, [maxResources, setMaxResources]);

  return (
    <AmazeePage location={location}>
      <div id="resources-page">
        <PageTitle title={title} amazeeOnly={true} />
        <AmazeeIoMetadata
          title={result.title}
          page_meta_description={result.page_meta_description}
          page_meta_thumbnail={result.page_meta_thumbnail}
          location={location}
        />

        <PageHeaderStandard
          hasSubnav={false}
          hasSectionDivider={false}
          header={result.hero_text.text}
          subheader={result.hero_description}
          image={result.hero_image}
          withSiteName={false}
          component={PageHeaderCustomV2}
        />

        <div className="container pt-10">
          <div className="row">
            {firstFeatured && <FeatureCard resource={firstFeatured} />}

            <ResourcePageFilter
              types={availableResourceTypes}
              topics={availableResourceTopics}
              industries={availableResourceIndustries}
              selectedType={selectedType}
              selectedTopic={selectedTopic}
              selectedIndustry={selectedIndustry}
              setIndustry={setSelectedIndustry}
              setTopic={setSelectedTopic}
              setType={setSelectedType}
            />

            {renderableResources.slice(0, 6).map((resouce, index) => (
              <StandardCard resource={resouce} key={index} />
            ))}

            <ResourceInteruption
              cta_text={result.cta_text}
              cta_button_text={result.cta_button_text}
              cta_link={result.cta_link}
              second_cta_button_text={result.second_cta_button_text}
              second_cta_button_link={result.second_cta_button_link}
            />

            {renderableResources
              .slice(6, maxResources)
              .map((resouce, index) => (
                <StandardCard resource={resouce} key={index} />
              ))}
          </div>

          {renderableResources.length > maxResources && (
            <div className="row">
              <div className="col-12 flex justify-center">
                <div
                  className="show-more-button rounded-full text-white"
                  onClick={incrementMaxResults}
                >
                  +
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </AmazeePage>
  );
};

export default ResourcePage;
